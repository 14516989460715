const canvasWave = (thePaper, canvas, segments, waves) => {
	thePaper.setup(canvas);

	const waveHeight = 35;

	function createSegments() {
		for (let i = 0; i < waves.length; i++) {
			const { fillColor, strokeWidth, strokeColor } = waves[i];

			let path = new thePaper.Path({
				fillColor,
				strokeWidth,
				strokeColor,
				closed: true
			});

			for (let j = 0; j <= segments + 1; j++) {
				path.add(new thePaper.Point(thePaper.view.size.width / segments * j, waveHeight));
			}
			path.add([thePaper.view.size.width + 5, thePaper.view.size.height + 5], [-5, thePaper.view.size.height + 5]);

			waves[i].segments = path.segments;
		}
	}
	createSegments();

	thePaper.view.onResize = () => {
		createSegments();
	}

	thePaper.view.onFrame = e => {
		for (let i = 0; i <= waves[0].segments.length - 3; i++) {
			for (let j = 0; j < waves.length; j++) {
				const { speed, shape, position, segments } = waves[j];
				const segment = segments[i];

				const sinus = Math.sin(e.time * speed + i / shape);

				segment.point.y = sinus * 34 + waveHeight + position;

				if (i > 0 && i < waves[0].segments.length - 3) {
					segment.smooth();
				}
			}
		}
	}
}
export { canvasWave };