import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import Separator from './Separator';

// Separator
document.addEventListener('DOMContentLoaded', () => {
	const allSeparator = document.querySelectorAll('.wp-block-sep-separator');
	allSeparator.forEach(separator => {
		const attributes = JSON.parse(separator.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<Separator attributes={attributes} />
		</>, separator);

		separator?.removeAttribute('data-attributes');
	});
});