const Style = ({ attributes, clientId }) => {
	const { position, height } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#sepSeparator-${clientId} .sepSeparator{
			height: ${'0px' === height || '0%' === height || '0em' === height ? '300px' : height};
			transform: ${'top' === position ? 'rotate(180deg)' : 'rotate(0deg)'};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;