import { useEffect, useRef } from '@wordpress/element';

import { canvasWave } from './Const/functions';

const Separator = ({ attributes }) => {
	const { segments, waves } = attributes;

	const sepSeparator = useRef(null);

	useEffect(() => {
		const sepPaper = new paper.PaperScope();

		canvasWave(sepPaper, sepSeparator.current, segments, waves);
	}, [sepSeparator, segments, waves]);

	return <canvas className='sepSeparator' resize ref={sepSeparator}></canvas>
}
export default Separator;